import React from "react";
import ContactImpl from "../components/ContactImpl";

const Contact = () => {

    return (
        <>
            <ContactImpl />     
        </>
    )
}

export default Contact;