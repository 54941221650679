import React from "react";
import Cards from "../components/Cards";


const AboutMe = () => {

    return (
        <>
            <Cards />
        </>
    )
}

export default AboutMe;