import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './layout/Navbar';
import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import Services from './pages/Services';
import Contact from './pages/Contact';


function App() {

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' Component={Home} />
          <Route path='/about-me' Component={AboutMe} />
          <Route path='/services' Component={Services} />
          <Route path='/contact' Component={Contact} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
