import React from "react";
import ServicesImpl from "../components/ServicesImpl";

const Services = () => {

    return (
        <>
            <ServicesImpl />
        </>
    )
}

export default Services;